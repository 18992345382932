












































































































































































































































































































































































































































































































































































































import { FormValidations } from "@/mixins/form-validations";
import { Navigation } from "@/mixins/navigation";
import { Permissions } from "@/mixins/permissions";
import PageTitle from "@/components/General/PageTitle.vue";
import Component, { mixins } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import Loader from "@/components/General/Loader.vue";
import Empty from "@/components/General/Empty.vue";
import { VueEditor } from "vue2-editor";
import { Notification } from "@/models/notification.interface";
import FilePicker from "@/components/Input/FilePicker.vue";
import { Status } from "@/models/status.interface";
import { EditorImages } from "@/mixins/editor-images";
import { StyleCheck } from "@/mixins/style-check";
import BarSearch from "@/components/Input/BarSearch.vue";
import DotsMenu from "@/components/General/DotsMenu.vue";
@Component({
  components: {
    PageTitle,
    Loader,
    Empty,
    VueEditor,
    FilePicker,
    BarSearch,
    DotsMenu,
  },
})
export default class PaymentPlatforms extends mixins(
  FormValidations,
  Navigation,
  Permissions,
  EditorImages,
  StyleCheck
) {
  $refs!: {
    paymentPlatformForm: HTMLFormElement;
    paymentPlatformFormUpdate: HTMLFormElement;
  };
  loader = false;
  loading = false;
  loadingDT = false;
  dialogCreate = false;
  dialogDelete = false;
  dialogUpdate = false;
  dialogPreview = false;
  show = false;
  status = [this.$constants.STATUS.ACTIVE, this.$constants.STATUS.INACTIVE];
  paymentPlatformsType = [
    this.$constants.PLATFORM_TYPE.CRYPTO,
    this.$constants.PLATFORM_TYPE.TRADITIONAL,
  ];
  defaultPaymentPlatform = {
    id: "",
    name: "",
    type: "",
    description: "",
    can_be_an_account: false,
    withdrawal_information: "",
    status: {
      name: "",
    },
    logo: {
      url: "",
      id: "",
    },
  };
  showErrorAnswer = false;
  paymentPlatformFile: File | null = null;
  filePreview = "";
  paymentPlatform = { ...this.defaultPaymentPlatform };
  search = "";
  options = {};

  pagination = {
    itemsPerPage: parseInt(process.env.VUE_APP_DEFAULT_PAGINATION),
    totalItems: 0,
    page: 1,
  };

  @Watch("paymentPlatformFile")
  previewFile(file: File): void {
    if (file != null) {
      this.filePreview = URL.createObjectURL(file);
    } else {
      if (!this.paymentPlatform.id) {
        this.filePreview = "";
        this.paymentPlatformFile = null;
      }
    }
  }

  @Watch("paymentPlatform.type")
  setPreviewDescription(type: string): void {
    if (
      this.paymentPlatform.description == "" ||
      this.paymentPlatform.description == null ||
      this.paymentPlatform.description == undefined ||
      this.dialogCreate
    ) {
      if (type == this.$constants.PLATFORM_TYPE.CRYPTO) {
        this.paymentPlatform.description = `
      <h4>${this.$tc("Views.pp-ejp1")}:</h4>
      <p><b>${this.$tc(
        "Views.pp-ejp2"
      )}:</b> du2h0j9oaksndbytqqqqa667293y78023jehysjdam</p>
      <p><b>Red:</b> Binance Smart Chain (BEP20)</p>
      <p><b>Token:</b> USDT</p>
    `;
      } else if (type == this.$constants.PLATFORM_TYPE.TRADITIONAL) {
        this.paymentPlatform.description = `
      <h4>${this.$tc("Views.pp-ejp3")}:</h4>
      <p><b>${this.$tc("Views.pp-ejp4")}:</b> Banesco Banco Universal</p>
      <p><b>${this.$tc("Views.pp-ejp5")}:</b> 6839276-383682023-3333</p>
      <p><b>${this.$tc("Views.pp-ejp6")}:</b> corriente</p>
      <p><b>${this.$tc("Views.pp-ejp7")}:</b> Carlos Soria</p>
      <p><b>${this.$tc("Views.pp-ejp8")}:</b> V-5679993</p>
    `;
      }
    }
  }

  private async paymentPlatformsByText() {
    this.loadingDT = true;

    await this.getPaymentPlatforms(
      this.pagination.page,
      this.pagination.itemsPerPage == -1
        ? this.pagination.totalItems
        : this.pagination.itemsPerPage,
      this.search
    );
    this.loadingDT = false;
  }

  @Watch("options", { deep: true })
  private async setItems(pagination) {
    this.loadingDT = true;
    this.pagination.itemsPerPage = pagination.itemsPerPage;

    await this.getPaymentPlatforms(
      pagination.page,
      pagination.itemsPerPage == -1
        ? this.pagination.totalItems
        : this.pagination.itemsPerPage,
      this.search
    );
    this.loadingDT = false;
  }

  private async getPaymentPlatforms(
    page: number,
    size: number,
    text = ""
  ): Promise<void> {
    await this.$store
      .dispatch("platform/getAllPaymentPlatforms", { page, size, text })
      .catch(() => {
        const Error: Notification = {
          message: this.$tc("Views.pp-error1"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };

        this.$store.dispatch("notifications/showNotification", Error);
      });
  }

  private async created() {
    this.loader = true;
    await this.getPaymentPlatforms(
      this.pagination.page,
      this.pagination.itemsPerPage,
      this.search
    );
    this.loader = false;
  }

  private get headers() {
    return [
      {
        text: this.$tc("Views.pp-h1"),
        value: "name",
        align: "center",
        sortable: false,
      },
      {
        text: this.$tc("Views.pp-h2"),
        value: "type",
        align: "center",
        sortable: false,
      },
      {
        text: this.$tc("Views.pp-h3"),
        value: "is_custom",
        align: "center",
        sortable: false,
      },
      {
        text: this.$tc("Views.pp-h4"),
        value: "can_be_an_account",
        align: "center",
        sortable: false,
      },
      {
        text: this.$tc("Views.pp-h5"),
        value: "status",
        align: "center",
        sortable: false,
      },
      {
        text: this.$tc("Views.pp-h6"),
        value: "actions",
        sortable: false,
        align: "center",
      },
    ];
  }

  private get paymentPlatforms() {
    let response = this.$store.getters["platform/getAllPaymentPlatforms"];
    this.pagination.totalItems = response.totalItems;
    this.pagination.page = parseInt(response.currentPage);

    if (response?.platforms) {
      return response.platforms;
    } else {
      return [];
    }
  }

  private async addPaymentPlatform() {
    this.loading = true;
    if (
      this.$refs.paymentPlatformForm.validate() &&
      this.validatedDescription() &&
      this.paymentPlatformFile !== null
    ) {
      await this.$store
        .dispatch("platform/addPaymentPlatform", {
          payment_platform: {
            name: this.paymentPlatform.name,
            type: this.paymentPlatform.type,
            description: this.paymentPlatform.description,
            can_be_an_account: this.paymentPlatform.can_be_an_account,
            withdrawal_information: this.paymentPlatform.withdrawal_information,
          },
        })
        .then(async (response) => {
          if (response.registered_name) {
            const Error: Notification = {
              message: this.$tc("Views.ppu-5"),
              timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
              top: true,
              bottom: false,
              left: false,
              right: false,
              currentPath: this.$route.fullPath,
              error: true,
            };

            this.$store.dispatch("notifications/showNotification", Error);
          } else {
            const payload = new FormData();
            payload.append(
              "DATA",
              JSON.stringify({ platform: { id: response.id } })
            );

            payload.append(
              this.$constants.FILE_CATEGORY.PLATFORM,
              this.paymentPlatformFile as File
            );

            await this.$store
              .dispatch("platform/uploadPaymentPlatformLogo", payload)
              .then(async () => {
                const Success: Notification = {
                  message: this.$tc("Views.pp-success1"),
                  timeout: this.$constants.NOTIFICATION_TIMEOUT.SUCCESS,
                  top: true,
                  bottom: false,
                  left: false,
                  right: false,
                  currentPath: this.$route.fullPath,
                  error: false,
                };

                this.$store.dispatch("notifications/showNotification", Success);
                await this.getPaymentPlatforms(
                  this.pagination.page,
                  this.pagination.itemsPerPage == -1
                    ? this.pagination.totalItems
                    : this.pagination.itemsPerPage,
                  this.search
                );
                this.close();
              })
              .catch(() => {
                const Error: Notification = {
                  message: this.$tc("Views.pp-error2"),
                  timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
                  top: true,
                  bottom: false,
                  left: false,
                  right: false,
                  currentPath: this.$route.fullPath,
                  error: true,
                };

                this.$store.dispatch("notifications/showNotification", Error);
              });
          }
        })
        .catch(() => {
          const Error: Notification = {
            message: this.$tc("Views.pp-error3"),
            timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
            top: true,
            bottom: false,
            left: false,
            right: false,
            currentPath: this.$route.fullPath,
            error: true,
          };

          this.$store.dispatch("notifications/showNotification", Error);
        })
        .finally(() => {
          this.loading = false;
          this.showErrorAnswer = false;
        });
    } else {
      if (this.validatedDescription()) {
        this.showErrorAnswer = false;
      } else {
        this.showErrorAnswer = true;
      }

      this.loading = false;
    }
  }

  private validatedDescription() {
    if (
      this.paymentPlatform.description !== "" &&
      this.paymentPlatform.description !== undefined &&
      this.paymentPlatform.description !== null
    ) {
      return true;
    } else {
      return false;
    }
  }

  private get paymentPlatformProgress(): boolean {
    return this.$store.getters["platform/getPaymentPlatformProgress"];
  }

  private async editPaymentPlatform(paymentPlatform: any) {
    this.paymentPlatform = { ...paymentPlatform };
    this.paymentPlatform.status = { ...paymentPlatform.status } as Status;
    this.filePreview = this.paymentPlatform.logo.url
      ? this.paymentPlatform.logo.url
      : "";
    this.dialogUpdate = true;
  }

  private async updatePaymentPlatform() {
    this.loading = true;
    if (
      this.$refs.paymentPlatformFormUpdate.validate() &&
      this.validatedDescription()
    ) {
      const payload = new FormData();
      payload.append(
        "DATA",
        JSON.stringify({
          platform: {
            id: this.paymentPlatform.id,
            name: this.paymentPlatform.name,
            type: this.paymentPlatform.type,
            description: this.paymentPlatform.description,
            can_be_an_account: this.paymentPlatform.can_be_an_account,
            withdrawal_information: this.paymentPlatform.withdrawal_information,
            logo: {
              id: this.paymentPlatform.logo.id,
            },
          },
        })
      );

      payload.append(
        this.$constants.FILE_CATEGORY.PLATFORM,
        this.paymentPlatformFile as File
      );
      await this.$store
        .dispatch("platform/updatePaymentPlatform", {
          data: payload,
          id: this.paymentPlatform.id,
        })
        .then(async () => {
          await this.$store.dispatch("platform/updatePaymentPlatformStatus", {
            id: this.paymentPlatform.id,
            status: this.paymentPlatform.status?.name,
          });

          const Success: Notification = {
            message: this.$tc("Views.pp-success2"),
            timeout: this.$constants.NOTIFICATION_TIMEOUT.SUCCESS,
            top: true,
            bottom: false,
            left: false,
            right: false,
            currentPath: this.$route.fullPath,
            error: false,
          };

          this.$store.dispatch("notifications/showNotification", Success);
          await this.getPaymentPlatforms(
            this.pagination.page,
            this.pagination.itemsPerPage == -1
              ? this.pagination.totalItems
              : this.pagination.itemsPerPage,
            this.search
          );
          this.close();
        })
        .catch(() => {
          const Error: Notification = {
            message: this.$tc("Views.pp-error4"),
            timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
            top: true,
            bottom: false,
            left: false,
            right: false,
            currentPath: this.$route.fullPath,
            error: true,
          };

          this.$store.dispatch("notifications/showNotification", Error);
        })
        .finally(() => {
          this.loading = false;
          this.showErrorAnswer = false;
        });
    } else {
      if (this.validatedDescription()) {
        this.showErrorAnswer = false;
      } else {
        this.showErrorAnswer = true;
      }
      this.loading = false;
    }
  }

  private async deletePaymentPlatformConfirm(paymentPlatform: any) {
    this.paymentPlatform = { ...paymentPlatform };
    this.dialogDelete = true;
  }

  private async deletePaymentPlatform() {
    this.loading = true;
    await this.$store
      .dispatch("platform/updatePaymentPlatformStatus", {
        id: this.paymentPlatform.id,
        status: this.$constants.STATUS.DELETED,
      })
      .then(async () => {
        const Success: Notification = {
          message: this.$tc("Views.pp-success3"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.SUCCESS,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: false,
        };

        this.$store.dispatch("notifications/showNotification", Success);
        await this.getPaymentPlatforms(
          this.pagination.page,
          this.pagination.itemsPerPage == -1
            ? this.pagination.totalItems
            : this.pagination.itemsPerPage,
          this.search
        );
        this.closeDelete();
      })
      .catch(() => {
        const Error: Notification = {
          message: this.$tc("Views.pp-error5"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };

        this.$store.dispatch("notifications/showNotification", Error);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  private closeDelete() {
    this.dialogDelete = false;
    this.paymentPlatform = Object.assign(
      this.paymentPlatform,
      this.defaultPaymentPlatform
    );
  }

  public resetValidations() {
    if (this.dialogCreate) {
      this.resetFormValidations([this.$refs.paymentPlatformForm]);
    } else {
      this.resetFormValidations([this.$refs.paymentPlatformFormUpdate]);
    }
  }

  private close() {
    this.$store.dispatch("platform/resetPaymentPlatformProgress");
    this.resetValidations();
    this.dialogCreate = false;
    this.dialogUpdate = false;
    this.paymentPlatformFile = null;
    this.filePreview = "";
    this.paymentPlatform = Object.assign(
      this.paymentPlatform,
      this.defaultPaymentPlatform
    );
  }

  private openDialogPreview(paymentPlatform: any) {
    this.paymentPlatform = { ...paymentPlatform };
    this.dialogPreview = true;
  }

  private closeDialogPreview() {
    try {
      this.paymentPlatform = Object.assign(
        this.paymentPlatform,
        this.defaultPaymentPlatform
      );
      this.resetValidations();
      this.dialogPreview = false;
    } catch {
      this.paymentPlatform = Object.assign(
        this.paymentPlatform,
        this.defaultPaymentPlatform
      );
      this.dialogPreview = false;
    }
  }
}
